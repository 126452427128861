import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import event from './event'
import langs from './langs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
    event,
    langs,
  },
  state: {},
  mutations: {},
  actions: {},
})
