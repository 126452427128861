<template>
  <a href="#" class="font-size-24">
    <router-link to="/log/models">
      <a-icon type="file-text" style="font-size: 22px" v-add-class-hover="'text-primary'" />
    </router-link>
  </a>
</template>

<script>
export default {}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
