var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout-sider',{class:{
      [_vm.$style.menu]: true,
      [_vm.$style.white]: _vm.settings.menuColor === 'white',
      [_vm.$style.gray]: _vm.settings.menuColor === 'gray',
      [_vm.$style.dark]: _vm.settings.menuColor === 'dark',
      [_vm.$style.unfixed]: _vm.settings.isMenuUnfixed,
      [_vm.$style.shadow]: _vm.settings.isMenuShadow,
    },attrs:{"width":_vm.settings.leftMenuWidth,"collapsible":false}},[_c('div',{class:_vm.$style.menuOuter,style:({
        width: _vm.settings.leftMenuWidth + 'px',
        height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
      })},[_c('a-menu',{class:_vm.$style.navigation,staticStyle:{"overflow-y":"auto","overflow-x":"hidden"},style:({
            height: _vm.settings.isMobileView || _vm.settings.isMenuUnfixed ? 'calc(100vh - 64px)' : 'calc(100vh - 68px)' }),attrs:{"forceSubMenuRender":"","inlineCollapsed":_vm.settings.isMobileView ? false : _vm.settings.isMenuCollapsed,"mode":"inline","selectedKeys":_vm.selectedKeys,"openKeys":_vm.openKeys,"inlineIndent":15,"theme":"light"},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event},"click":_vm.handleClick,"openChange":_vm.handleOpenChange}},[_vm._l((_vm.menuData),function(item,index){return [(((item.roles && _vm.userHasAnyRole(_vm.user, item.roles)) || (_vm.userCanView(_vm.user, item.key))) && item.show)?[(item.category)?_c('a-menu-item-group',{key:index},[_c('template',{slot:"title"},[_vm._v(_vm._s(item.title))])],2):_vm._e(),(!item.children && !item.category)?_c('item',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e(),(item.children)?_c('sub-menu',{key:item.key,attrs:{"menu-info":item,"styles":_vm.$style}}):_vm._e()]:_vm._e()]})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }