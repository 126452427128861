<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <!-- <router-link to="/">Home</router-link> -->
      <template v-for="(item, index) in breadcrumb">
        <span :key="index">
          <router-link :to="item.to">{{item.text}}</router-link>
          <span :class="$style.arrow"></span>
        </span>
      </template>
      <!-- <span v-if="activeItem">
        <span :class="$style.arrow"></span>
        <span :class="$style.current">{{activeItem.title}}</span>
      </span> -->
    </div>
<!--    <h3>{{ $route.meta.title }}</h3>-->
  </div>
<!--  <div>-->
<!--    <a-breadcrumb :routes="$route.matched">-->
<!--      <template slot="itemRender" slot-scope="{ route, params, paths }">-->
<!--        <span v-if="$route.matched.indexOf(route) === $route.matched.length - 1">-->
<!--          {{ route.name }}1-->
<!--        </span>-->
<!--        <router-link v-else :to="`/${paths.join('/')}`">-->
<!--          {{ route.name }}1-->
<!--        </router-link>-->
<!--      </template>-->
<!--    </a-breadcrumb>-->
<!--    <br />-->
<!--    {{ $route.path }}-->
<!--  </div>-->
</template>

<script>
import { mapState } from 'vuex'
import { getMenuData } from '@/services/menu'
import reduce from 'lodash/reduce'

/* eslint-disable */

export default {
  name: 'breadcrumbs',
  data() {
    return {
      breadcrumb: [],
      activeItem: {},
      path: [],
      // routes: this.$route.matched,
    }
  },
  computed: {
    ...mapState(['settings']),
    menuData() {
      return getMenuData
    },
  },
  methods: {
    getBreadcrumbs() {
      let pathArray = this.$route.path.split("/")
      pathArray.pop()

      let breadcrumbs = [];
      pathArray.map((path, idx) => {
        breadcrumbs.push({
          path: path,
          to: breadcrumbs[idx-1]
            ? ((breadcrumbs[idx-1].path == "") ? "" : "/" + breadcrumbs[idx-1].path) + "/" + path
            : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path,
        });
      });

      // let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
      //   breadcrumbArray.push({
      //     path: path,
      //     to: breadcrumbArray[idx]
      //       ? "/1" + breadcrumbArray[idx].path + "/" + path
      //       : "/2" + path,
      //     text: this.$route.matched[idx].meta.breadCrumb || path,
      //   });

      //   console.log(1, path, idx, breadcrumbArray[idx])

      //   return breadcrumbArray;
      // }, [])

      return breadcrumbs
    },
    getPath(data, url, parents = []) {
      if (url === '/') {
        url = '' // '/dashboard/alpha'
      }
      const items = reduce(
        data,
        (result, entry) => {
          if (result.length) {
            return result
          }
          if (entry.children) {
            const nested = this.getPath(entry.children, url, [entry].concat(parents))
            return (result || []).concat(nested.filter(e => !!e))
          }
          if (entry.url === url) {
            return [entry].concat(parents)
          }
          return result
        },
        [],
      )
      this.activeItem = items[0]
      return items
    },
  },
  mounted: function () {
    this.breadcrumb = this.getBreadcrumbs()
  },
  watch: {
    $route(to) {
      this.breadcrumb = this.getBreadcrumbs()
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
