import apiClient from '@/services/axios'
import { mapState } from 'vuex'
import store from 'store'

export default {
  data: () => ({}),
  created() {},
  mounted() {
    try {
      if (this.checkAdminRole(this.user.roles)) {
        // if (typeof store.get('app.users.roles') === 'undefined' || store.get('app.users.roles').length === 0) {
        this.getRoles()
        // }
        // if (typeof store.get('app.users.permissions') === 'undefined' || store.get('app.users.permissions').length === 0) {
        this.getPermissions()
        // }
      }
    } catch (e) {
      // console.log('Ошибка в mounted accessmix.js')
    }
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    checkAdminRole: function (userRoles) {
      const adminRole = userRoles.find(obj => {
        return obj.id === 1
      })
      return typeof adminRole !== 'undefined'
    },
    checkPermissions(userPermissions, module, type) {
      if (userPermissions) {
        const checkPerm = userPermissions.find(obj => {
          return obj.name === module + '.' + type
        })
        return typeof checkPerm !== 'undefined'
      }
      return false
    },
    getPermissionByName(permName) {
      const perm = store.get('app.users.permissions').find(obj => {
        return obj.name === permName
      })
      if (typeof perm !== 'undefined') { return perm } else { return false }
    },
    getRoleByName(roleName) {
      const role = store.get('app.users.roles').find(obj => {
        return obj.name === roleName
      })
      if (typeof role !== 'undefined') { return role } else { return false }
    },
    getPermissionsByRole(roleID) {
      const role = store.get('app.users.roles').find(obj => {
        return obj.id === roleID
      })
      return role.permissions
    },
    getPermissionsByRoleName(roleName) {
      const role = store.get('app.users.roles').find(obj => {
        return obj.name === roleName
      })
      return role.permissions
    },
    getPermissionsByRoles(roles) {
      const permissions = []
      roles.forEach(function(role) {
        const roleObj = store.get('app.users.roles').find(obj => {
          return obj.id === role
        })
        const rolePerms = roleObj.permissions
        rolePerms.forEach(function(rolePerm) {
          if (!permissions.some(pObj => pObj.name === rolePerm.name)) {
            permissions.push(rolePerm)
          }
        })
      })
      permissions.sort(function(a, b) {
        return a.id - b.id || a.name.localeCompare(b.name)
      })
      return permissions.map(({ id, name }) => ({ id, name }))
    },
    permissionExists(permName) {
      const found = store.get('app.users.permissions').find(obj => {
        return obj.name === permName
      })
      return typeof found !== 'undefined'
    },
    getDifferenceArray(arrayOne, arrayTwo) {
      return arrayOne.filter(({ name: id1 }) => !arrayTwo.some(({ name: id2 }) => id2 === id1))
    },
    getDifferenceOfNotRolePermissions(roles) {
      return this.getDifferenceArray(store.get('app.users.permissions'), this.getPermissionsByRoles(roles))
    },
    getPermissionsCategories() {
      const perms = store.get('app.users.permissions')
      const permsNames = []
      perms.forEach(function(perm) {
        const shortName = perm.name.split('.')[0]
        if (!permsNames.includes(shortName)) {
          permsNames.push(shortName)
        }
      })
      return permsNames
    },
    getPermissionTypesByName(permName) {
      return store.get('app.users.permissions').filter(x => x.name.includes(permName))
    },
    roleHasPermission(roleName, permName) {
      const role = store.get('app.users.roles').find(obj => {
        return obj.name === roleName
      })
      if (typeof role !== 'undefined') {
        const perm = role.permissions.find(obj => {
          return obj.name === permName
        })
        return typeof perm !== 'undefined'
      } else {
        return false
      }
    },
    permissionHasRole(permName, roleName) {
      const perm = store.get('app.users.permissions').find(obj => {
        return obj.name === permName
      })
      if (typeof perm !== 'undefined') {
        const role = perm.roles.find(obj => {
          return obj.name === roleName
        })
        return typeof role !== 'undefined'
      } else {
        return false
      }
    },
    addPermissionToRole(pObj, roleName) {
      const roles = store.get('app.users.roles')
      // const role = store.get('app.users.roles').find(obj => {
      //   return obj.name === roleName
      // })
      const ind = roles.findIndex(x => x.name === roleName)
      roles[ind].permissions.push(pObj)
      store.set('app.users.roles', roles)
    },
    removePermissionFromRole(permName, roleName) {
      const roles = store.get('app.users.roles')
      const ind = roles.findIndex(x => x.name === roleName)
      if (ind >= 0) { roles[ind].permissions.splice(ind, 1) }
      store.set('app.users.roles', roles)
    },
    removePermissionFromAllRoles(permName) {
      const roles = store.get('app.users.roles')
      roles.forEach((role, index, object) => {
        const ind = role.permissions.findIndex(x => x.name === permName)
        if (ind >= 0) { object[index].permissions.splice(ind, 1) }
      })
      store.set('app.users.roles', roles)
    },
    removeRoleFromAllPermissions(roleName) {
      const perms = store.get('app.users.permissions')
      perms.forEach((perm, index, object) => {
        const ind = perm.roles.findIndex(x => x.name === roleName)
        if (ind >= 0) { object[index].roles.splice(ind, 1) }
      })
      store.set('app.users.permissions', perms)
    },
    roleSyncPermissions(roleName, permissions) {
      const allPerms = store.get('app.users.permissions')
      const roles = store.get('app.users.roles')
      const ind = roles.findIndex(x => x.name === roleName)
      roles[ind].permissions = permissions
      store.set('app.users.roles', roles)
      allPerms.forEach((perm, index, object) => {
        if (this.permissionHasRole(perm.name, roleName) && !this.roleHasPermission(roleName, perm.name)) {
          const ind1 = object[index].roles.findIndex(x => x.name === roleName)
          object[index].roles.splice(ind1, 1)
        } else if (!this.permissionHasRole(perm.name, roleName) && this.roleHasPermission(roleName, perm.name)) {
          const role = this.getRoleByName(roleName)
          object[index].roles.push({ id: role.id, name: role.name })
        }
      })
      store.set('app.users.permissions', allPerms)
    },
    permissionSyncRoles(permName, roles) {
      const allRoles = store.get('app.users.roles')
      const perms = store.get('app.users.permissions')
      const ind = perms.findIndex(x => x.name === permName)
      perms[ind].roles = roles
      store.set('app.users.permissions', perms)
      allRoles.forEach((role, index, object) => {
        if (this.roleHasPermission(role.name, permName) && !this.permissionHasRole(permName, role.name)) {
          const ind1 = object[index].permissions.findIndex(x => x.name === permName)
          object[index].permissions.splice(ind1, 1)
        } else if (!this.roleHasPermission(role.name, permName) && this.permissionHasRole(permName, role.name)) {
          const perm = this.getPermissionByName(permName)
          object[index].permissions.push({ id: perm.id, name: perm.name })
        }
      })
      store.set('app.users.roles', allRoles)
    },
    userHasRole(roleName) {
      if (this.user && this.user?.roles?.length) {
        const found = this.user.roles.find(obj => {
          return obj.name === roleName
        })
        return typeof found !== 'undefined'
      }
      return false
    },
    userHasAnyRole(user, roleNames) {
      try {
        var hasAny = false
        for (const roleName of roleNames) {
          const finded = user.roles.find(obj => {
            return obj.name === roleName
          })
          if (typeof finded !== 'undefined') {
            hasAny = true
            break
          }
        }
      } catch (e) {
        // console.log('Ошибка в userHasAnyRole')
      }
      return hasAny
    },
    userCanOperate(user, moduleName, pType) {
      try {
        const isPromoImages = moduleName === 'promoImages'

        const found = user.permissions.find(obj => {
          if (isPromoImages) {
            return ['banners', 'stories'].some(name => obj.name === name + '.' + pType)
          } else {
            return obj.name === moduleName + '.' + pType
          }
        })
        return typeof found !== 'undefined'
      } catch (e) {
        // console.log('Ошибка в userCanOperate')
      }
    },
    userCanView(user, moduleName) {
      return this.userCanOperate(user, moduleName, 'view')
    },
    userCanCreate(user, moduleName) {
      return this.userCanOperate(user, moduleName, 'create')
    },
    userCanEdit(user, moduleName) {
      return this.userCanOperate(user, moduleName, 'edit')
    },
    userCanDelete(user, moduleName) {
      return this.userCanOperate(user, moduleName, 'delete')
    },
    getRoles() {
      const url = '/admin/roles'
      apiClient.get(url).then((response) => {
        store.set('app.users.roles', response.data.data)
      }).catch(error => { console.log(error) })
    },
    getPermissions() {
      const url = '/admin/permissions'
      apiClient.get(url).then((response) => {
        store.set('app.users.permissions', response.data.data)
      }).catch(error => { console.log(error) })
    },
    isNumber: function(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
  },
}
