<template>
    <a-form-model
        ref="decryptionKeyForm"
        :model="form"
        :rules="rules"
        class="decryption-key-modal"
    >
      <div v-if="savedKey" class="mb-4 text-gray-8">
        Previously saved key: <a-tag>{{ savedKey.substring(0,16) }}*</a-tag>
        <a-tooltip title="Delete key from session storage" placement="bottom">
          <a-popconfirm
            title="Sure to delete saved key from session storage?"
            @confirm="() => deleteSavedKey()"
          >
            <a href="javascript:"><a-icon type="delete" class="text-danger" /></a>
          </a-popconfirm>
        </a-tooltip>
      </div>
      <a-form-model-item ref="key" label="Decryption key" prop="key">
          <a-input v-model="form.key" placeholder="Enter decryption key" />
      </a-form-model-item>
      <a-row>
        <a-col class="text-left" :span="12">
          <a-button @click="closeKeyModal">
            Cancel
          </a-button>
        </a-col>
        <a-col class="text-right" :span="12">
          <a-button type="primary" @click="onSubmit">
            Save
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
</template>
<script>
export default {
  name: 'decryptionKeyModal',
  mixins: [],
  computed: {
    // ---
  },
  data() {
    return {
      savedKey: sessionStorage.getItem('app.encryption.privatekey'),
      form: {
        key: '',
      },
      rules: {
        key: [{ required: true, message: 'Please input decryption key', trigger: 'change' }],
      },
    }
  },
  created () {
    // ---
  },
  methods: {
    closeKeyModal() {
      this.$emit('closeModalFunction')
    },
    submitKeyModal(key) {
      this.$emit('submitModalFunction', key)
    },
    deleteKeyModal(key) {
      this.$emit('deleteModalFunction', key)
    },
    onSubmit() {
      this.$refs.decryptionKeyForm.validate(valid => {
        if (valid) {
          this.saveKey()
        } else {
          console.log('error save key submit!!')
          return false
        }
      })
    },
    saveKey() {
      sessionStorage.setItem('app.encryption.privatekey', this.form.key)
      this.savedKey = this.form.key
      this.$notification.success({
        message: 'Decryption key successfully saved to session storage',
        description: '',
      })
      this.submitKeyModal(this.savedKey)
    },
    deleteSavedKey() {
      sessionStorage.removeItem('app.encryption.privatekey')
      this.savedKey = null
      this.$notification.success({
        message: 'Decryption key successfully deleted from session storage',
        description: '',
      })
      this.deleteKeyModal(this.savedKey)
    },
  },
}
</script>
<style scoped>
.ant-form-item-label {
    padding: 0px !important;
}
</style>
