<template>
  <div :class="$style.topbar">
<!--    <div class="mr-auto">-->
<!--      <cui-search />-->
<!--    </div>-->
<!--    <div class="mr-4 d-none d-md-block">-->
<!--      <cui-issues-history />-->
<!--    </div>-->
   <!-- <div class="mb-0 mr-auto d-xl-block d-none">-->
<!--      <cui-project-management />-->
<!--    </div> -->
    <router-link to="/" :class="$style.logoContainer">
      <div :class="$style.logo">
        <img src="/resources/images/logo.svg" class="mr-2" alt="Clean UI" />
        <div :class="$style.name"><span class="text-primary">{{ settings.logo }}</span>Bet</div>
      </div>
    </router-link>
    <div class="d-flex">
      <div class="mr-4">
        <a-tooltip title="Cloud Store" placement="bottom">
          <tp-cloud-store class="mt-0" />
        </a-tooltip>
        <!-- <a-tooltip title="Settings" placement="bottom">
          <tp-settings />
        </a-tooltip> -->
      </div>
      <div class="mr-4" v-if="userHasRole('admin')">
        <a-tooltip title="Decryption Key" placement="bottom">
          <tp-decryption-key class="mt-0" />
        </a-tooltip>
      </div>
      <div class="mr-4" v-if="userCanView(this.user, 'model_logs')">
        <a-tooltip title="Model Change Log" placement="bottom">
          <tp-model-logs class="mt-0" />
        </a-tooltip>
      </div>
      <div class="mr-4 d-none d-sm-block" v-if="userHasRole('admin')">
        <cui-panel-management />
      </div>
  <!--    <div class="mr-4">-->
  <!--      <cui-fav-pages />-->
  <!--    </div>-->
  <!--    <div class="mr-4 d-none d-sm-block">-->
  <!--      <cui-language-switcher />-->
  <!--    </div>-->
  <!--    <div class="mr-4 d-none d-sm-block">-->
  <!--      <cui-actions />-->
  <!--    </div>-->
      <div>
        <cui-user-menu />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import CuiIssuesHistory from './IssuesHistory'
import CuiPanelManagement from './PanelManagement'
// import CuiSearch from './Search'
// import CuiLanguageSwitcher from './LanguageSwitcher'
// import CuiActions from './Actions'
import CuiUserMenu from './UserMenu'
// import CuiFavPages from './FavPages'
import TpCloudStore from './CloudStore'
import TpModelLogs from './ModelLogs'
import TpDecryptionKey from './DecryptionKey'
// import TpSettings from './Settings'
import accessMix from '@/services/accessmix.js'

export default {
  mixins: [accessMix],
  components: {
    // CuiIssuesHistory,
    // CuiProjectManagement,
    CuiPanelManagement,
    // CuiSearch,
    // CuiLanguageSwitcher,
    // CuiActions,
    CuiUserMenu,
    // CuiFavPages,
    TpCloudStore,
    TpModelLogs,
    TpDecryptionKey,
    // TpSettings,
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
