import apiClient from '@/services/axios'
import store from 'store'

export async function login(email, password, remember) {
  return apiClient
    .post('/admin/authenticate', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        console.log(response.data)
        const { accessToken, expiresAt } = response.data
        if (accessToken) {
          if (remember) {
            store.set('accessToken', accessToken)
            console.log('AT after login (remember token): ' + accessToken)
          } else {
            document.cookie = `accessToken=${accessToken}`
            console.log('AT after login (not remember token): ' + accessToken)
          }
        }
        if (expiresAt) { store.set('expiresAt', expiresAt) }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function refresh(remember) {
  return apiClient
    .get('/admin/user/token/refresh')
    .then(response => {
      if (response) {
        console.log('refresh token response', response.data)
        const { accessToken, expiresAt } = response.data
        if (accessToken) {
          if (remember) {
            store.set('accessToken', accessToken)
            console.log('AT after refresh (remember token): ' + accessToken)
          } else {
            document.cookie = `accessToken=${accessToken}`
            console.log('AT after login (not remember token): ' + accessToken)
          }
        }
        if (expiresAt) { store.set('expiresAt', expiresAt) }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/admin/user')
    .then(response => {
      if (response) {
        // const { accessToken } = response.data
        const { success } = response.data
        // const { email, name } = response.data.data
        if (success) {
          // store.set('accessToken', accessToken)
          // console.log('AT after currentAccount: ' + success)
          // console.log(email + ' / ' + name)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/admin/user/logout')
    .then(() => {
      store.remove('accessToken')
      document.cookie = 'accessToken=;max-age=-1;'
      return true
    })
    .catch(err => console.log(err))
}
