var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',[_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"100%","background":"rgba(24, 144, 255, 0.32)"}}),_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"100%","background":"linear-gradient(180deg, rgba(0, 0, 0, 0.6) 16.34%, rgba(0, 0, 0, 0) 100%)"}}),_c('a-layout-content',[_c('div',{class:{
          [_vm.$style.container]: true,
          cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
          cui__layout__cardsShadow: _vm.settings.isCardShadow,
          cui__layout__borderless: _vm.settings.isBorderless,
          [_vm.$style.white]: _vm.settings.authPagesColor === 'white',
          [_vm.$style.gray]: _vm.settings.authPagesColor === 'gray',
        },style:({backgroundImage: _vm.settings.authPagesColor === 'image' ? `url(resources/images/auth_bg.jpg)` : 'none'})},[_c('div',{class:{
          [_vm.$style.topbar]: false,
          [_vm.$style.topbarGray]: _vm.settings.isGrayTopbar,
        }}),_c('div',{class:_vm.$style.containerInner,staticStyle:{"z-index":"1000"}},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }